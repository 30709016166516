import { memo } from "react"
import { LinkBox, LinkOverlay, Box, Heading, Text, Image } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import { CustomLink } from "@components/Link"
import { useContent } from "@app/hooks/useContent"

const StoreCard = ({ store }) => {
  const { getContent } = useContent()
  const { urlResolver } = useRoutes()

  const item = {
    ...store,
    link: urlResolver(store),
    image: {
      alt: store?.image?.alt || "",
      src: store?.image?.asset?.url || "",
    },
    content: getContent({ content: store?.content }),
  }
  return (
    <LinkBox as="article" maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      {item?.image?.src && <Image boxSize="100%" objectFit="cover" src={item?.image?.src} alt={item?.image?.alt} />}
      <Box p="4" textAlign="left">
        <Heading as="h3" fontSize="xl" fontWeight={700} lineHeight="tight" noOfLines={1}>
          <LinkOverlay as={CustomLink} to={item?.link?.url} title={item?.title}>
            {item?.title}
          </LinkOverlay>
        </Heading>

        {item?.address && <Text fontSize="sm">{item?.address}</Text>}
        {item?.description && <Text fontSize="sm">{item?.description}</Text>}
      </Box>
    </LinkBox>
  )
}

export default memo(StoreCard)
