import { Container, SimpleGrid, Box, Heading } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { memo } from "react"
import StoreCard from "./StoreCard"

import type { Props } from "@app/pages/store"
import type { PageProps } from "@root/types/global"

const StoreList: React.FC<PageProps<Props>> = ({ page, stores }) => {
  const { getContent } = useContent()

  const item = {
    ...page,
    image: {
      alt: page?.image?.alt || "",
      src: page?.image?.asset?.url || "",
    },
    content: getContent({ content: page?.content }),
  }

  const items = stores?.edges?.map(({ node }) => node)
  return (
    <Container maxW="container.lg">
      <Box as="section" py={8} textAlign="center">
        <Heading as="h1" mb={4}>
          {item?.title}
        </Heading>

        <Box mb={8}>{item?.content}</Box>

        <SimpleGrid columns={[1, 3]} spacing={10}>
          {items && items?.map((store, index) => <StoreCard key={index} store={store} />)}
        </SimpleGrid>
      </Box>
    </Container>
  )
}
export default memo(StoreList)
